import React from 'react'
import { config, useSpring } from 'react-spring'
import { readableColor } from 'polished'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'

const Contact = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const PButton = styled(Button)<{ color: string }>`
    background: ${props => (props.color === 'white' ? 'black' : props.color)};
    color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  `

  return (
    <Layout>
      <SEO title="Contact | ToriArt" desc="Get in touch!" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Willst du mich kontaktieren?</h1>
        <br />
        <p>Danke für’s vorbeischauen!</p>
        <p>Gerne darfts du mich bei Fragen, Kommentaren oder Interesse kontaktieren.</p>
        <br />
        <PButton color="lightgrey" py={3} px={5}>
          <a style={{ textDecoration: 'none' }} href="mailto:dombra@gmx.ch?subject=Website%20Anfrage">
            Schick doch eine mail!
          </a>
        </PButton>
      </AnimatedBox>
    </Layout>
  )
}

export default Contact
